import { COOKIE_EVENT_TIME, COOKIE_MENU_TYPE } from '../components/constants';
import { ConfigValue } from '../services/config-service/types';
import { MenuType } from '../services';
import { getCookie } from './get-cookie';
import { DateTime } from 'luxon';

const getEventDayAtMidnightInLAAsGMT = (eventTimeStamp: string) => {
    const eventTime = DateTime.fromSeconds(Number(eventTimeStamp), { zone: 'America/Los_Angeles' });
    return eventTime.set({ hour: 0, minute: 0, second: 0 });
};

const getEventDayAtEndOfDayInLAAsGMT = (eventTimeStamp: string) => {
    const eventTime = DateTime.fromSeconds(Number(eventTimeStamp), { zone: 'America/Los_Angeles' });
    return eventTime.set({ hour: 23, minute: 59, second: 59 });
};

export const checkOrderWindow = (commerceConfig: ConfigValue | undefined): boolean => {
    const eventTimeStamp = getCookie(COOKIE_EVENT_TIME);
    if (commerceConfig && eventTimeStamp) {
        const menuType: MenuType = getCookie(COOKIE_MENU_TYPE) as MenuType;
        const orderStart = commerceConfig.orderEventStartHours;
        const preOrderEnd = commerceConfig.preOrderEventEndHours;
        const eventTime = DateTime.fromSeconds(Number(eventTimeStamp), { zone: 'America/Los_Angeles' });
        const currentTime = DateTime.now();
        const timeDifference = eventTime.diff(currentTime, 'hours');
        if (timeDifference.hours < orderStart) {
            const eventTimeEndOfDay = getEventDayAtEndOfDayInLAAsGMT(eventTimeStamp);
            const difference = eventTimeEndOfDay.diff(currentTime);
            if (difference.hours <= 0) {
                return false;
            }
        }
        if (menuType === 'PRE') {
            const adjustedEventTime = getEventDayAtMidnightInLAAsGMT(eventTimeStamp);
            const preTimeDifference = adjustedEventTime.diff(currentTime, 'hours');
            if (preTimeDifference.hours < preOrderEnd) {
                return false;
            }
        }
    }
    return true;
};
