import React, { useState } from 'react';
import { CartLine, suspendOrder, MenuType } from '../../services';
import {
    COOKIE_CLOSE_WEB_VIEW_URL,
    URL_IMAGES,
    COOKIE_MENU_TYPE,
    ERROR_MESSAGE_WINDOW_ORDER_ERROR,
    PLACEHOLDER_IMAGE
} from '../../components/constants';
import { getCookie, numberToCurrentcy, transformUrl, checkOrderWindow } from '../../utils';
import { useCategory, useNavigation, useOrderManagement, useConfig } from '../../context';
import OrderSummary from './order-summary';
import { ServiceFeeDisclaimer } from '../../components';
import { ErrorPage } from '../error-page';
import { defaultFontFamily } from '../../styles';
import { DividerIcon, DisabledAddIcon, DisabledRemoveIcon, RemoveIcon, AddIcon } from '../../icons';
import { Img } from 'react-image';

export const CartDetail = () => {
    const [isSuspendingOrder, setIsSuspendingOrder] = useState(false);
    const [orderWindowError, setOrderWindowError] = useState(false);
    const { order, updateProducts, deleteProducts } = useOrderManagement();
    const { navigateToCategoriesPage, navigateToCartItem } = useNavigation();
    const { handleCategorySelection } = useCategory();
    const menuType: MenuType = getCookie(COOKIE_MENU_TYPE) as MenuType;
    const { commerceConfig, isNewNavigation } = useConfig();

    const handleSuspendOrder = async () => {
        const isInOrderWindow = checkOrderWindow(commerceConfig);
        const closeWebViewUrl = getCookie(COOKIE_CLOSE_WEB_VIEW_URL);
        setIsSuspendingOrder(true);
        if (!isNewNavigation && closeWebViewUrl) {
            window.location.href = closeWebViewUrl;
        } else if (isInOrderWindow && closeWebViewUrl) {
            await suspendOrder();
            setIsSuspendingOrder(false);
            window.location.href = closeWebViewUrl;
        } else {
            setOrderWindowError(true);
            setIsSuspendingOrder(false);
        }
    };

    const handleCloseWebview = () => {
        const isInOrderWindow = checkOrderWindow(commerceConfig);
        const closeWebViewUrl = getCookie(COOKIE_CLOSE_WEB_VIEW_URL);
        if (isInOrderWindow && closeWebViewUrl) {
            window.location.href = closeWebViewUrl;
        } else {
            setOrderWindowError(true);
        }
    };

    const handleAddMoreProducts = () => {
        const mainCategoryFromCookie = typeof window !== 'undefined' ? Number(getCookie('categoryId')) || 0 : 0;
        handleCategorySelection(mainCategoryFromCookie);
        navigateToCategoriesPage();
    };

    const getCartLineComment = (cartLine: CartLine) => {
        const specialRequest = cartLine?.attributeValues?.find(attribute => attribute.Name === 'Special_Request');
        return specialRequest ? specialRequest.TextValue : '';
    };

    const getCartLineMenuType = (cartLine: CartLine) => {
        const menuType = cartLine?.attributeValues?.find(attribute => attribute.Name === 'Menu_Item_Type');
        return menuType?.TextValue;
    };

    const removeProduct = async (lineId: string | undefined) => {
        await deleteProducts([{ lineId }]);
    };

    const updateProduct = async (lineId: string | undefined, quantity: number) => {
        await updateProducts([{ lineId, quantity }]);
    };

    return orderWindowError ? (
        <ErrorPage title={ERROR_MESSAGE_WINDOW_ORDER_ERROR}>
            <button
                onClick={() => setOrderWindowError(false)}
                style={{
                    ...defaultFontFamily,
                    background: 'black',
                    borderRadius: '20px 0 20px 0',
                    color: 'white',
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    width: '100%',
                    height: '48px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: 600,
                    alignSelf: 'center'
                }}
            >
                Close
            </button>
        </ErrorPage>
    ) : (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <h1 style={{ fontSize: '20px', fontWeight: 700, margin: '24px' }}>Items</h1>
                <ul style={{ listStyleType: 'none', padding: '0px 24px', margin: 0 }}>
                    {order?.cart.cartLines.map((item: CartLine) => (
                        <li
                            key={item.lineId}
                            style={{
                                ...defaultFontFamily,
                                marginBottom: '10px'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                                <div>
                                    <Img
                                        src={[transformUrl(`${URL_IMAGES}${item?.product?.primaryImageUrl}`), PLACEHOLDER_IMAGE]}
                                        alt={item?.product?.name ?? ''}
                                        style={{ width: 'auto', height: 'auto', maxWidth: '52px', maxHeight: '52px' }}
                                    />
                                </div>
                                <div style={{ display: 'grid' }}>
                                    <span style={{ ...defaultFontFamily, fontWeight: 700, marginBottom: '4px' }}>
                                        {!!item.description ? item.product?.name : '-'}
                                    </span>
                                    <div
                                        style={{
                                            ...defaultFontFamily,
                                            marginBottom: '4px',
                                            backgroundColor: '#E2E2E2',
                                            height: '20px',
                                            width: 'fit-content',
                                            padding: '2px 4px'
                                        }}
                                    >
                                        {getCartLineMenuType(item) === 'DOE' ? 'Order' : 'Pre-order'}
                                    </div>
                                    <span style={{ ...defaultFontFamily, marginBottom: '4px' }}>
                                        ${numberToCurrentcy(item.price * item.quantity)}
                                    </span>
                                </div>
                                <div style={{ display: 'flex', gap: '16px', marginLeft: 'auto', minWidth: '140px' }}>
                                    <button
                                        style={{
                                            border: 'none',
                                            background: 'transparent',
                                            cursor: 'pointer',
                                            margin: 0,
                                            padding: 0
                                        }}
                                        aria-label='Decrease Item'
                                        disabled={item.quantity === 0 || (menuType === 'DOE' && getCartLineMenuType(item) === 'PRE')}
                                        onClick={
                                            item.quantity === 1
                                                ? () => removeProduct(item.lineId)
                                                : () => updateProduct(item.lineId, item.quantity - 1)
                                        }
                                    >
                                        {item.quantity === 0 || (menuType === 'DOE' && getCartLineMenuType(item) === 'PRE') ? (
                                            <DisabledRemoveIcon />
                                        ) : (
                                            <RemoveIcon />
                                        )}
                                    </button>
                                    <p style={{ fontWeight: 600, fontSize: '32px', margin: '0px auto' }}>{item.quantity}</p>
                                    <button
                                        style={{
                                            border: 'none',
                                            background: 'transparent',
                                            cursor: 'pointer',
                                            margin: 0,
                                            padding: 0
                                        }}
                                        aria-label='Increase Item'
                                        disabled={menuType === 'DOE' && getCartLineMenuType(item) === 'PRE'}
                                        onClick={() => updateProduct(item.lineId, item.quantity + 1)}
                                    >
                                        {menuType === 'DOE' && getCartLineMenuType(item) === 'PRE' ? <DisabledAddIcon /> : <AddIcon />}
                                    </button>
                                </div>
                            </div>
                            <div>
                                {getCartLineComment(item) ? (
                                    <span
                                        style={{
                                            ...defaultFontFamily,
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            fontStyle: 'italic',
                                            display: 'block'
                                        }}
                                    >
                                        Note: {getCartLineComment(item)}
                                    </span>
                                ) : null}
                                {menuType === 'PRE' && getCartLineMenuType(item) === 'PRE' ? (
                                    <button
                                        style={{
                                            ...defaultFontFamily,
                                            border: 'none',
                                            background: 'transparent',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            marginTop: '10px',
                                            padding: 0,
                                            fontSize: '14px',
                                            fontWeight: 700,
                                            color: '#121212'
                                        }}
                                        onClick={() => navigateToCartItem(item)}
                                    >
                                        {getCartLineComment(item) ? 'Edit note' : 'Add note'}
                                    </button>
                                ) : null}
                            </div>
                            <DividerIcon />
                        </li>
                    ))}
                </ul>
            </div>
            <div style={{ padding: '0px 24px' }}>
                <button
                    style={{
                        ...defaultFontFamily,
                        background: 'transparent',
                        borderRadius: '20px 0 20px 0',
                        color: 'black',
                        padding: '12px',
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                        width: '100%',
                        textAlign: 'center',
                        maxWidth: '348px',
                        cursor: 'pointer',
                        marginBottom: '12px',
                        fontSize: '16px',
                        fontWeight: 600
                    }}
                    onClick={handleAddMoreProducts}
                >
                    + ADD MORE ITEMS
                </button>
                <OrderSummary order={order!} />
                <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '24px',
                        background: '#FFFFFF',
                        display: 'flex',
                        justifyContent: 'space-between',
                        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)'
                    }}
                >
                    {
                        <>
                            <div style={{ display: 'grid' }}>
                                <span style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 400 }}>
                                    {`Total: ${order?.cart.totalItems || 0} items`}
                                </span>
                                <span style={{ ...defaultFontFamily, fontSize: '24px', fontWeight: 700 }}>
                                    ${numberToCurrentcy(order?.cart.totalAmount || 0)}
                                </span>
                                <span style={{ ...defaultFontFamily, fontSize: '14px', fontWeight: 400 }}>Fees included</span>
                            </div>
                            <button
                                onClick={order?.paymentMethodId ? handleSuspendOrder : handleCloseWebview}
                                style={{
                                    ...defaultFontFamily,
                                    background: 'black',
                                    borderRadius: '20px 0 20px 0',
                                    color: 'white',
                                    textDecoration: 'none',
                                    textTransform: 'uppercase',
                                    width: '100%',
                                    height: '48px',
                                    textAlign: 'center',
                                    maxWidth: '200px',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    alignSelf: 'center'
                                }}
                                disabled={isSuspendingOrder}
                            >
                                {order?.paymentMethodId ? 'UPDATE ORDER' : 'ADD PAYMENT METHOD'}
                            </button>
                        </>
                    }
                </div>
            </div>
            <div style={{ paddingBottom: '130px' }}>
                <ServiceFeeDisclaimer />
            </div>
        </>
    );
};
