import React from 'react';
import { InfoIcon } from '../../icons/info-icon';
import { disclaimerStyle } from '../../styles';

export const AddNotesDisclaimer = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', margin: '24px 24px 12px 24px' }}>
            <div>
                <InfoIcon />
            </div>
            <p style={disclaimerStyle}>Please note you can add notes for each product in the product detail view.</p>
        </div>
    );
};
