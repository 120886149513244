import React, { createContext, useContext, useState, ReactNode } from 'react';
import {
    PAGE_CART_DETAIL,
    PAGE_CATEGORY,
    PAGE_CATEGORY_LIST,
    PAGE_PRODUCT_DETAIL,
    PAGE_ITEM_LIST,
    COOKIE_ORDER_ID,
    COOKIE_SECTION
} from '../components/constants';
import { CartLine, Product } from '../services';
import { Category } from '../services/category/get-category/types';
import { useCategory } from './category-context';
import { getCookie, setCookie, setLocationHash } from '../utils';

interface NavigationContextProps {
    currentPage: string;
    navigateToCart: () => void;
    navigateToCategoriesPage: () => void;
    navigateToItem: (product: Product) => void;
    navigateToCartItem: (cartline: CartLine) => void;
    navigateToCategoryProductsPage: (categoryId?: number) => void;
    setCurrentPage: (page: string) => void;
    navigateToCategoryPage: (c: Category) => void;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return context;
};

export const NavigationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { handleCategorySelection } = useCategory();
    const [currentPage, setCurrentPage] = useState<string>(PAGE_CATEGORY_LIST);

    const section = getCookie(COOKIE_SECTION);
    const orderId = getCookie(COOKIE_ORDER_ID);

    if (orderId && section && section === 'order') {
        setCookie(COOKIE_SECTION, 'category');
        setLocationHash(PAGE_CART_DETAIL);
    }

    const navigateToItem = (product: Product) => {
        setLocationHash(`${PAGE_PRODUCT_DETAIL}/${product.productId}`);
    };

    const navigateToCartItem = (cartline: CartLine) => {
        setLocationHash(`${PAGE_PRODUCT_DETAIL}/${cartline.itemId}`);
    };

    const navigateToCategoryProductsPage = (categoryId?: number) => {
        setLocationHash(`${PAGE_ITEM_LIST}/${categoryId}`);
        if (categoryId) {
            handleCategorySelection(categoryId);
        }
    };

    const navigateToCart = () => {
        setLocationHash(PAGE_CART_DETAIL);
    };

    const navigateToCategoriesPage = () => {
        setLocationHash(PAGE_CATEGORY);
    };

    const navigateToCategoryPage = (c: Category): void => {
        if (!c?.nestedCategories || c.nestedCategories.length === 0) {
            navigateToCategoryProductsPage(c.categoryId);
        } else {
            setLocationHash(PAGE_CATEGORY_LIST);
            handleCategorySelection(c.categoryId);
        }
    };

    return (
        <NavigationContext.Provider
            value={{
                currentPage,
                setCurrentPage,
                navigateToItem,
                navigateToCartItem,
                navigateToCategoryProductsPage,
                navigateToCart,
                navigateToCategoriesPage,
                navigateToCategoryPage
            }}
        >
            {children}
        </NavigationContext.Provider>
    );
};
