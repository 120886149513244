import React from 'react';
import { ErrorIcon } from '../icons';
import { defaultFontFamily } from '../styles';
import { ERROR_MESSAGE_TITLE } from '../components/constants';

interface ErrorPageProps {
    title?: string;
    message?: string;
    children?: React.ReactNode;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ title, message, children }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                overflow: 'hidden',
                ...defaultFontFamily
            }}
        >
            <div className='error-bg' style={{ textAlign: 'center', paddingTop: '160px' }}>
                <ErrorIcon />
                <div style={{ margin: '50px 24px 0 24px' }}>
                    <span className='error-title' style={{ marginBottom: '200px', display: 'block' }}>
                        {title || ERROR_MESSAGE_TITLE}
                    </span>
                    {message ? <div style={{ ...defaultFontFamily, fontWeight: 400 }}>{message}</div> : null}
                    {children}
                </div>
            </div>
        </div>
    );
};
