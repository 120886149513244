import React, { useMemo } from 'react';
import { Img } from 'react-image';
import { URL_IMAGES, PLACEHOLDER_IMAGE } from '../../components/constants';
import { Category } from '../../services/category/get-category/types';
import { CartLine } from '../../services';
import { transformUrl } from '../../utils';
import { useCategory, useNavigation, useOrderManagement } from '../../context';
import { defaultFontFamily } from '../../styles';
import { ArrowIcon } from '../../icons';

interface CategoriesPageProps {
    categories: Category[];
    categoryId?: number;
    navigateToCategoryPage: (category: Category) => void;
    navigateToCategoryProductsPage: (categoryId: number) => void;
    cookieCategory?: Category;
}

export const CategoriesPage = ({ cookieCategory }: CategoriesPageProps) => {
    const { filteredCategories } = useCategory();
    const { navigateToCategoryProductsPage } = useNavigation();
    const { order, isOrderLoading } = useOrderManagement();

    const itemsCount = useMemo(() => {
        return order?.cart?.cartLines.reduce((total: number, cartLine: CartLine) => total + cartLine.quantity, 0) ?? 0;
    }, [order?.cart?.cartLines]);

    const handleErrorHeader = (e: any) => {
        e.target.src = '';
        e.target.style.display = 'none';
    };

    const generateLink = (c: Category) => {
        return (
            <div key={c.categoryId} id={`category-${c.categoryId}`} style={{ border: '1px solid #e2e2e2', borderWidth: '1px 0' }}>
                <button
                    style={{
                        ...defaultFontFamily,
                        color: 'black',
                        cursor: 'pointer',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'left',
                        padding: 0,
                        width: '100%',
                        display: 'flex',
                        minHeight: '80px'
                    }}
                    onClick={() => navigateToCategoryProductsPage(c.categoryId)}
                >
                    <Img
                        style={{ aspectRatio: '1/1', width: '48px', margin: '16px 16px 16px 24px', borderRadius: '50%' }}
                        src={[transformUrl(`${URL_IMAGES}${c.image}`), PLACEHOLDER_IMAGE]}
                        alt={c.name}
                    />
                    <p style={{ fontSize: '14px', fontWeight: 600, lineHeight: '16px', margin: 'auto 0' }}>{c?.name}</p>
                    <div style={{ margin: 'auto 24px auto auto' }}>
                        <ArrowIcon />
                    </div>
                </button>
            </div>
        );
    };

    return (
        <div>
            {cookieCategory?.image ? (
                <img
                    style={{ height: '150px', display: 'flex', margin: 'auto' }}
                    src={transformUrl(`${URL_IMAGES}${cookieCategory.image}`)}
                    alt={cookieCategory.name}
                    onError={e => handleErrorHeader(e)}
                />
            ) : null}
            {cookieCategory?.name ? (
                <h2 className='header-title' style={{ margin: '8px 24px' }}>
                    {cookieCategory?.name}
                </h2>
            ) : null}
            {cookieCategory?.localizedDescription ? (
                <p style={{ ...defaultFontFamily, margin: '0px 24px 24px 24px', lineHeight: '1.2' }}>
                    <span style={{ display: 'block' }}>{cookieCategory?.localizedDescription}</span>
                </p>
            ) : null}
            <div style={{ marginBottom: '24px', paddingBottom: !isOrderLoading && order && itemsCount > 0 ? '115px' : '0px' }}>
                {filteredCategories.map((c: Category) => generateLink(c))}
            </div>
        </div>
    );
};
